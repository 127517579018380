// toast.service.ts
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private messageService: MessageService, private toastService: ToastrService,) { }

    success(detail: string) {
        this.toastService.success(detail, 'Success!');
    }

    error(detail: string) {
        this.toastService.error(detail, 'Error!');
    }

    warn(detail: string) {
        this.toastService.warning(detail, 'Warning!');
    }

    info(detail: string) {
        this.toastService.info(detail, 'Info!');
    }
}
