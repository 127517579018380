import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../../@core/models/model';
import { FullNamePipe } from './fullName.pipe';
import { Avatar } from 'primeng/avatar';
import { first } from 'rxjs';

@Pipe({
    standalone: true,
    name: 'userAvatar'
})

export class UserAvatarPipe implements PipeTransform {
    transform(user: IUser | null | undefined): string {
        if (!user) {
            return '';
        }
        let fullName = new FullNamePipe().transform(user);
        return `https://avatar.iran.liara.run/public?username=${fullName}`;
    }
}

