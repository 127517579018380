
import { Document, IAttachment, IUser, IVendee } from "../../@core/models/model"
import { CONTRACT_STATUS } from "../../@shared/components/badge/badge.enums"


export enum CREATE_CONTRACT_TYPES {
    EMPTY = 'EMPTY',
    UPLOAD = 'UPLOAD',
    GENERATE = 'GENERATE'
}

export enum IContractRole {
    OWNER = 'OWNER',
    CONTRACTOR = 'CONTRACTOR',
    NONE = 'NONE'
}

export interface IContract extends Document {
    builder: string | IVendee,
    attachment: string | IAttachment['_id'] | IAttachment,
    name: string,
    description: string,
    checkpoint: string,
    users: {
        user: string | IUser['_id'] | IUser,
        role: string
    }[],
    status: CONTRACT_STATUS,
    createdBy: string | IUser['_id'] | IUser,
    defaultSignature: CONTRACT_SIGNATORY_TYPE,
    docusignId: string,
    adobeSignId: string,
    updatedAt: string
}

export interface IContractSection {
    title: string;
    content: string;
}

export interface IContractInsights extends Document {
    summary: string;
    sections: IContractSection[];
    isValidContract: boolean;
    isShortContract: boolean;
    role: IContractRole;
    contract: IContract | string | IContract['_id'];
    createdAt: Date;
    updatedAt: Date;
}

export interface IContractApprovalConf extends Document {
    contract: string | IContract['_id'] | IContract,
    approvers: {
        user: IUser,
        status: string
    }[],
    createdBy: IUser,
    status: string,
}

export interface IContractSignatoryConf extends Document {
    contract: string | IContract['_id'] | IContract,
    signatories: {
        email: string,
        name: string,
        status: string,
        signedAttachment?: string
    }[],
    createdBy: IUser,
    status: string,
}

export enum CONTRACT_SIGNATORY_TYPE {
    DOCU_SIGN = 'DOCU_SIGN',
    ADOBE_SIGN = 'ADOBE_SIGN',
}

