import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IContract, IContractApprovalConf } from '../../../contract-management/models/contract-management.model';
import { CONTRACT_STATUS, INTEGRATION_STATUS } from './badge.enums';
import { CONTRACT_APPROVAL_STATUS } from '../user-chips/user-chips.component';
import { CONTRACT_USER_ROLE } from '../../../@core/models/model';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss'
})
export class BadgeComponent {

  @Input() contract!: IContract

  @Input() approver!: IContractApprovalConf
  @Input() integration!: string

  @Input() permission!: string
  badgeText: string = CONTRACT_STATUS.DRAFT
  badgeType: string = 'default'
  constructor() { }

  ngOnInit(): void {
    if (this.approver) {
      this.getBadgeColorApproval(this.approver?.status || CONTRACT_APPROVAL_STATUS.PENDING)
    }
    if (this.contract) {
      this.getBadgeColor(this.contract?.status || CONTRACT_STATUS.DRAFT)
    }
    if (this.permission) {
      console.log(this.permission)
      this.getPermissionBadgeColor(this.permission || CONTRACT_USER_ROLE.OWNER)
    }
    if (this.integration) {
      this.getIntegrationBadgeColor(this.integration ?? INTEGRATION_STATUS.DISCONNECTED)
    }
  }

  getIntegrationBadgeColor(status: string) {
    console.log("status", status);

    switch (status) {
      case INTEGRATION_STATUS.CONNECTED:
        this.badgeText = 'Connected'
        this.badgeType = 'success'
        break;
      case INTEGRATION_STATUS.DISCONNECTED:
        this.badgeText = 'Disconnected'
        this.badgeType = 'danger'
        break;
      default:
        this.badgeText = 'Disconnected'
        this.badgeType = 'danger'
        break;
    }
  }

  getBadgeColor(status: string) {
    switch (status) {
      case CONTRACT_STATUS.PENDING_APPROVAL:
        this.badgeText = 'Pending Approval'
        this.badgeType = 'pending'
        break;
      case CONTRACT_STATUS.APPROVED:
        this.badgeText = 'Approved'
        this.badgeType = 'success'
        break;
      case CONTRACT_STATUS.REJECTED:
        this.badgeText = 'Rejected'
        this.badgeType = 'danger'
        break;
      case CONTRACT_STATUS.READY_FOR_SIGNATURE:
      case CONTRACT_STATUS.SIGNATURE_IN_PROGRESS:
        this.badgeText = 'Signature Pending'
        this.badgeType = 'pending'
        break;
      case CONTRACT_STATUS.SIGNATURE_COMPLETED:
        this.badgeText = 'Signed'
        this.badgeType = 'success'
        break;
      default:
        this.badgeText = 'Draft'
        this.badgeType = 'default'
        break;
    }
  }

  getBadgeColorApproval(status: string) {
    switch (status) {
      case CONTRACT_APPROVAL_STATUS.PENDING:
        this.badgeText = 'Pending Approval'
        this.badgeType = 'pending'
        break;
      case CONTRACT_STATUS.SIGNED:
        this.badgeText = 'Signed'
        this.badgeType = 'success'
        break;
      case CONTRACT_STATUS.APPROVED:
        this.badgeText = 'Approved'
        this.badgeType = 'success'
        break;
      case CONTRACT_STATUS.REJECTED:
        this.badgeText = 'Rejected'
        this.badgeType = 'danger'
        break;
      default:
        this.badgeText = 'Waiting'
        this.badgeType = 'default'
        break;
    }
  }

  getPermissionBadgeColor(permission: string) {
    switch (permission) {
      case CONTRACT_USER_ROLE.VIEWER:
        this.badgeText = 'View only'
        this.badgeType = 'info'
        break;
      case CONTRACT_USER_ROLE.EDITOR:
        this.badgeText = 'Editor'
        this.badgeType = 'primary'
        break;
      case CONTRACT_USER_ROLE.COMMENTER:
        this.badgeText = 'Commenter'
        this.badgeType = 'pending'
        break;
      default:
        this.badgeText = 'Owner'
        this.badgeType = 'primary'
        break;
    }
  }
}

