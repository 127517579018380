import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from "rxjs";
import { BaseService } from "../../@core/services/base.service";
import { CONTRACT_SIGNATORY_TYPE, IContract, IContractApprovalConf, IContractSignatoryConf } from "../models/contract-management.model";
import { IUser } from "../../@core/models/model";

interface IContractFilters {
    searchValue?: string,
    date?: Date
}

@Injectable({ providedIn: 'root' })
export class ContractService extends BaseService {
    connectedUsers$ = new BehaviorSubject<IUser[]>([]);

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }


    apiUrl = environment.apiUrl + '/contract-management'

    getContracts(filters: { filters: IContractFilters, skip: number, limit: number }) {
        return this.get<IContract[]>(this.apiUrl + '/all-contracts', { filters: JSON.stringify(filters) })
    }

    getSharedContracts() {
        return this.get<IContract[]>(this.apiUrl + '/shared-contracts')
    }

    shareContract(contractId: string, users: { user: IUser, role: string }[]) {
        return this.post<IContract>(this.apiUrl + '/share-contract/' + contractId, { users })
    }
    getSharedUsers(contractId: string) {
        return this.get<any>(this.apiUrl + '/shared-users/' + contractId)
    }
    fetchContract(contractId: string) {
        return this.get<IContract>(this.apiUrl + '/fetch-contract/' + contractId)
    }

    updateSignatories(contractId: string, signatories: { email: string, name: string }[]) {
        return this.post<IContract>(this.apiUrl + '/add-signatories/' + contractId, { signatories })
    }


    fetchContractSignatories(contractId: string) {
        return this.get<IContractSignatoryConf>(this.apiUrl + '/fetch-contract-signatories/' + contractId)
    }

    deleteDocRole(contractId: string, user: IUser['_id']) {
        return this.delete<string>(this.apiUrl + '/remove-doc-role/' + contractId + '/' + user)
    }
    changeDocRole(contractId: string, user: IUser['_id'], role: string) {
        return this.post<IContract>(this.apiUrl + '/change-user-role/' + contractId + '/' + user, { role })
    }

    createContract(data: FormData) {
        return this.post<IContract>(this.apiUrl + '/create-contract', data)
    }

    updateContract(contractId: string, contract: IContract): Observable<any> {
        return this.http.put(`${this.apiUrl}/edit-contract/${contractId}`, contract);
    }

    uploadContract(formData: FormData) {
        return this.post<IContract>(this.apiUrl + '/upload-contract', formData)
    }

    startSignatoryFlow(contractId: string, signatoryType: CONTRACT_SIGNATORY_TYPE) {
        return this.post<IContract>(this.apiUrl + '/start-signatory-flow', {
            contractId,
            signatoryType
        })
    }

    resetSignatoryFlow(contractSignatoryConfId: string) {
        return this.post<IContract>(this.apiUrl + '/reset-signatory-flow/' + contractSignatoryConfId)
    }

    setDefaultSignatory(signatory: CONTRACT_SIGNATORY_TYPE) {
        return this.post<IContract>(this.apiUrl + '/set-default-signatory', { signatory })
    }

    getDefaultSignatory() {
        return this.get<{
            defaultSignatory: CONTRACT_SIGNATORY_TYPE,
        }>(this.apiUrl + '/get-default-signatory')
    }

    downloadDocument(attachmentId: string) {
        return this.get<any>(this.apiUrl + '/download-signed-document/', { attachmentId })
    }

    fetchLastModifiedContract() {
        return this.get<any>(this.apiUrl + '/last-modified-contract')
    }
}
