
<span
    class="inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset"
    [ngClass]="{
        'bg-green-100 text-green-800 ring-green-300': badgeType === 'success',
        'bg-red-100 text-red-800 ring-red-300': badgeType === 'danger',
        'bg-yellow-100 text-yellow-800 ring-yellow-300': badgeType === 'pending',
        'bg-blue-100 text-blue-800 ring-blue-300': badgeType === 'info',
        'bg-gray-100 text-gray-800 ring-gray-300': badgeType === 'default'
  }">
    {{badgeText}}
</span>