import { USER_TYPE } from "./enums";

export interface ResponseJson<T> {
    data: T;
    success: boolean;
    message: string;
}


export interface Document {
    _id: string
}

export interface IVendee extends Document {
    name: string;
    companyId: string;
    office_address: {
        street_address: string,
        city: string,
        state: string,
        pincode: string,
    },
    contact_person: string;
    contact_no: string;
    contact_email: string;
    email: string;
    company_contact_no: string;
    gst_no: string
    pan_no: string
    vendee_logo: IAttachments,
    oneDriveId: string;
    googleDriveId: string;
    subscriptions: {
        HOME: boolean,
        PROGRESS: boolean,
        QUALITY: boolean,
        SAFETY: boolean,
        DRAWING_MANAGEMENT: boolean,
        DATA_ANALYTICS: boolean,
        DATA_TRACKING: boolean,
        PRODUCTIVITY: boolean,
        P6: boolean,
        AWP: boolean,
        DOCUMENT_MANAGEMENT: boolean,
        QUICK_APPS: boolean,
        TIME_MANAGEMENT: boolean,
        EQUIPMENT_MANAGEMENT: boolean,
        INVENTORY_MANAGEMENT: boolean,
        PROCUREMENT: boolean,
        BIM: boolean,
        GIS: boolean,
        BID_MANAGEMENT: boolean,
        BID_MANAGEMENT_US: boolean,
        LEAD_MANAGEMENT: boolean,
        PROPOSAL_MANAGEMENT: boolean,
        WORKORDER: boolean,
        WORK_ORDER_CONTRACT: boolean,
        FABRICATION: boolean
        ADOBE_SIGN: boolean,
        EXPENSE_TRACKER: boolean,
        VIEWPOINT: boolean,
        ENTERPRISE_DASHBOARD: boolean,
        GLOBAL_CHAT: boolean,
        TASK_MANAGEMENT: boolean,
        GOOGLE_MAPS: boolean,
        AI_CHAT: boolean,
        CAPITAL_MANAGEMENT: boolean
    },
    currency: string
};

export interface IAttachments extends Document {
    name: string,
    attachment: IAttachment['_id']
}


export interface IAttachment extends Document {
    filename: string;
    url: string;
    type: string;
    createdAt?: Date;
    updatedAt?: Date;
    isPublic: boolean;
    fullUrl?: string;
    relatedTo?: string;
    project?: string,
    superRef?: string | IAttachment
    filePath?: string
}


export enum USER_TYPES {
    INNCIRCLES = 'INNCIRCLES',
    VENDEE_ADMIN = 'VENDEE_ADMIN',
    OWNER = 'OWNER',
    ADMIN = 'ADMIN'
}

export enum USER_AVAILABILITY_STATUS {
    AVAILABLE = 'AVAILABLE',
    BUSY = 'BUSY',
    DO_NOT_DISTURB = 'DO_NOT_DISTURB',
    BE_RIGHT_BACK = 'BE_RIGHT_BACK',
    APPEAR_OFFLINE = 'APPEAR_OFFLINE',
    OUT_OF_OFFICE = 'OUT_OF_OFFICE'
}

export enum SIGNATURE_TYPES {
    ATTACHMENT = 'ATTACHMENT',
    INITIALS = 'INITIALS',
    CANVAS = 'CANVAS',
}


export interface IUser extends Document {
    username: string;
    password: string;
    title: string;
    linkedRoster?: string;
    firstName: string;
    lastName: string;
    employeeId: string;
    phoneNumber: string;
    email: string;
    type: USER_TYPES;
    availabilityStatus: USER_AVAILABILITY_STATUS;
    profilePicture: IAttachment;
    profile: string;
    active: boolean;
    id: string;
    permissions?: string[];
    signature: {
        signType: SIGNATURE_TYPES;
        attachment: IAttachment['_id'];
        initials: string;
        canvas: string;
    };
    token?: string;
    project?: string;
    builder: string;
    // assets?: string[] | IAttachment[];
    profileUrl?: string;
    outlookMailId?: string;
    outlookGuid?: string;
    fullName?: string;
    vendorNumber?: string,
    colorCode: string;
    assets?: { attachment: IAttachment, name: string }[]
}

export enum CONTRACT_USER_ROLE {
    OWNER = 'OWNER',
    EDITOR = 'Editor',
    VIEWER = 'Viewer',
    COMMENTER = 'Commenter'
}
