import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'fullName'
})

export class FullNamePipe implements PipeTransform {
    transform(user: any, ...args: any[]): any {
        if (!user) {
            return '';
        }

        if (!user.firstName) user.firstName = user.fullName
        return user.firstName ? (user.lastName ? user.firstName + ' ' + user.lastName : user.firstName) : (user.lastName ? user.lastName : (user.email ? user.email : ''));
    }
}